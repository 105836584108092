import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Header from "../components/header";
import Seo from "../components/seo";

import { formatURL } from "../utils/helpers";

const BlogPost = ({ pageContext: { pageInfo } }) => {
  let postsInfo = pageInfo.post;
  const postList = pageInfo.postList;
  let postDate = new Date(postsInfo.date).toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Layout>
      <Seo title={postsInfo.title} />
      <Helmet>
        <meta name="twitter:image" content={postsInfo.image} />
      </Helmet>

      <Header />
      <main className="new">
        <div className="content">
          <img alt="Imagen de cabecera" src={postsInfo.image} />
          <div className="post-title">
            <h1>{postsInfo.title}</h1>
          </div>{" "}
          <div className="post-description">
            <p>{postsInfo.description}</p>
          </div>
          <div className="post-meta">
            <time>Escrito el {postDate}</time>
          </div>
          <div
            className="post-content"
            dangerouslySetInnerHTML={{ __html: postsInfo.content }}
          />{" "}
        </div>{" "}
        <aside>
          <Link to="/noticias" className="btn-green">
            Ver todas las noticias
          </Link>
          <hr />

          <h3>Noticias relacionadas</h3>
          <ul className="related-news">
            {postList.map((post, index) => {
              return (
                <li key={index}>
                  <Link to={`/noticia/${formatURL(post.title)}`}>
                    {post.title}
                  </Link>
                </li>
              );
            })}
          </ul>
          <hr />
          <h3>Enlaces de interés</h3>
          <ul>
            <li>
              <Link to="/aviso-legal">Aviso legal</Link>
            </li>
          </ul>
        </aside>
      </main>
    </Layout>
  );
};

export default BlogPost;
